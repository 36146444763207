import * as R from "ramda"
import React from "react"
import Helmet from "react-helmet"
import { useSiteMeta } from "src/hooks"
import { graphql, PageProps } from "gatsby"
import { Challenge, LoadingScreen } from "src/components"
import { useSqliteDatabaseContext } from "src/context/sqliteDb"
import Layout from "src/layout"

import { CommonTypes, ChallengeTypes } from "src/types"

interface QueryResult {
  mdx: ChallengeTypes.ChallengeNodeFull
}
interface PostPageContext {
  prevNode?: ChallengeTypes.ChallengeNode
  nextNode?: ChallengeTypes.ChallengeNode
  slug: CommonTypes.Slug
}

const ChallengePage: React.FC<PageProps<QueryResult, PostPageContext>> = ({ pageContext, data }) => {
  const siteMeta = useSiteMeta()
  const challenge = data.mdx
  const { prevNode, nextNode, slug } = pageContext

  return (
    <>
      <Helmet>
        <title>{`${challenge?.frontmatter?.title} | ${siteMeta.site.siteMetadata.title}`}</title>
      </Helmet>
      <Layout>
        <Challenge prevNode={prevNode} nextNode={nextNode} slug={slug} challenge={challenge} />
      </Layout>
    </>
  )
}

const ChallengePageContainer: React.FC<PageProps<QueryResult, PostPageContext>> = ({ pageContext, data, ...props }) => {
  const dbContext = useSqliteDatabaseContext()

  if (!R.isNil(dbContext)) {
    const { initializing } = dbContext

    if (initializing) return <LoadingScreen />

    return <ChallengePage pageContext={pageContext} data={data} {...props} />
  }

  return <LoadingScreen />
}

export default ChallengePageContainer

/* eslint no-undef: "off" */
export const singleChallengeQuery = graphql`
  query ChallengeBySlugQuery($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      body
      tableOfContents
      timeToRead
      excerpt
      wordCount {
        words
        sentences
        paragraphs
      }
      headings {
        value
        depth
      }
      frontmatter {
        id
        challengeId
        databaseName
        tags
        category
        title
        slug
        date
      }
      fields {
        slug
        date
        filePath
      }
    }
  }
`
